
import { defineComponent, reactive, Ref, ref, toRaw, watchEffect } from 'vue';
import {
  CreditCardOutlined,
  DollarCircleOutlined,
  FileProtectOutlined,
} from '@ant-design/icons-vue';
import CardForm from '@/components/CardForm.vue';
import ECheckForm from '@/components/ECheckForm.vue';
import { useForm } from '@ant-design-vue/use';
import { PaymentFormType } from '@/types/payment-form.type';
import { useIntegrations, useGlobalProps } from '@/composables';
import { IntegrationsService } from '@/services';
import { Validator } from '@/utils/ant-custom-validators';

interface State {
  type: PaymentFormType;
  autopay: boolean;
  account: string | null;
}

export default defineComponent({
  components: {
    CardForm,
    ECheckForm,
    CreditCardOutlined,
    DollarCircleOutlined,
    FileProtectOutlined,
  },
  setup() {
    const { setPaymentMethodSelected, form } = useGlobalProps();
    const refCardForm = ref<typeof CardForm>() as Ref<typeof CardForm>;
    const refECheckForm = ref<typeof ECheckForm>() as Ref<typeof ECheckForm>;

    const { accounts, setAccounts, hasPrimaPayIntegration } = useIntegrations();

    const state = reactive<State>({
      type: 'credit_card',
      autopay: false,
      account: null,
    });

    const rules = reactive({
      account: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
    });

    const primaAccounts = ref<any[]>([]);

    /** Form validation result (AntDesign) */
    const { validateInfos, validate } = useForm(state, rules);

    /** ----- Methods ------ */
    const filterOption = (input: string, option: any) => {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    /** Validate form and return data */
    const validateForm = async () => {
      if (state.type === 'credit_card' || state.type === 'e_check') {
        // Credit card mode (primapay)
        try {
          // const primaPayRef = refCardForm;
          const primaPayRef =
            state.type === 'credit_card' ? refCardForm : refECheckForm;

          if (!primaPayRef.value.savedPaymentMethod.id) {
            const [, primaPayIsDone] = await Promise.all([
              validate('account'),
              primaPayRef.value.validate(),
            ]);

            if (!primaPayIsDone) {
              throw 'Invalid form fields';
            }
          } else {
            const accountIsDone = await Promise.all([validate('account')]);

            if (!accountIsDone) {
              throw 'Invalid account field';
            }
          }

          // Validation successfully
          const primaPay = await primaPayRef.value.primaPayRequest();
          return Promise.resolve({ ...toRaw(state), primaPay });
        } catch (error) {
          return Promise.reject(error);
        }
      } else {
        // Cash mode
        let done = true;

        // Validate other fields
        try {
          await validate();
        } catch (error) {
          done = false;
        }

        if (!done) {
          return Promise.reject(new Error('Invalid form values'));
        }

        return Promise.resolve(toRaw(state));
      }
    };

    const setStateType = () => {
      if (!hasPrimaPayIntegration.value) {
        state.type = 'cash';
        return;
      }
    };

    /** Execute request on create component */
    const executeRequests = async () => {
      if (accounts.value.length) return;

      // Get accounts
      try {
        primaAccounts.value = await IntegrationsService.getAccounts();
        setAccounts(primaAccounts.value);
        setStateType();
      } catch (error) {
        console.error(error);
      }
    };

    executeRequests();

    const alternAccounts = () => {
      state.account = null;

      if (state.type === 'credit_card' || state.type === 'e_check') {
        setAccounts(
          primaAccounts.value.filter(
            account =>
              account.lexChargeAccountId && account.lexChargeAccountId !== null,
          ),
        );

        state.autopay = true;
      } else {
        setAccounts(primaAccounts.value);
        state.autopay = false;
      }

      return;
    };

    watchEffect(() => {
      alternAccounts();

      setPaymentMethodSelected(state.type);
    });

    return {
      state,
      accounts,
      validateInfos,
      filterOption,
      validateForm,
      refCardForm,
      alternAccounts,
      primaAccounts,
      setStateType,
      hasPrimaPayIntegration,
      refECheckForm,
      form,
    };
  },
});
