<template>
  <Stepper :currentContact="currentContact" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Stepper from '@/components/Stepper.vue';

export default defineComponent({
  components: {
    Stepper,
  },
  props: {
    currentContact: {
      type: Object,
    },
  },
});
</script>
