<template>
  <!-- Pdf viewer draft -->
  <ViewerPdf :draft="true" #default="{ download }">
    <slot :download="download" />
  </ViewerPdf>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ViewerPdf from './ViewerPdf.vue';

export default defineComponent({
  components: { ViewerPdf },
  setup() {
    return {};
  },
});
</script>

<style lang="scss"></style>
