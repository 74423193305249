<template>
  <div class="container">
    <div class="Stepper">
      <ASteps :current="step" size="small">
        <AStep
          v-for="item in steps"
          :key="item.title"
          :title="item.title"
          :description="item.description"
        />
      </ASteps>

      <div class="Stepper__content">
        <h2 class="Stepper__content-title">{{ steps[step].title }}</h2>
        <keep-alive>
          <component
            :is="stepComponent"
            #default="{ validateFn, download }"
            :currentContact="currentContact"
          >
            <img
              v-if="paymentMethodSelected !== 'cash' && step === 3"
              style="position: absolute; margin-left: -45%; margin-top: 35px"
              src="../assets/images/primaPay/primaPay.png"
              width="100"
            />

            <div class="Stepper__actions">
              <AButton
                v-if="step < steps.length - 1"
                :disabled="step <= 0"
                style="margin-right: 8px"
                @click="prevStep"
              >
                <LeftOutlined />
                Previous
              </AButton>

              <AButton
                v-if="step < steps.length - 2"
                type="primary"
                style="margin-right: 8px"
                @click="onSubmit(validateFn)"
              >
                Next
                <RightOutlined />
              </AButton>
              <AButton
                v-if="step == 3"
                type="primary"
                @click="onDone(validateFn)"
              >
                Done
                <CheckOutlined />
              </AButton>

              <AButton
                v-if="step == steps.length - 1"
                style="margin-right: 8px"
                @click="onClose"
              >
                Close
                <CloseOutlined />
              </AButton>
              <AButton
                v-if="step === 2 || step === 4"
                style="margin-right: 8px"
                type="primary"
                @click="onDonwload(download)"
              >
                Download
                <DownloadOutlined />
              </AButton>
            </div>
          </component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { message } from 'ant-design-vue';
import { computed, defineComponent, reactive, toRefs } from 'vue';
import {
  RightOutlined,
  LeftOutlined,
  CheckOutlined,
  DownloadOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import Step5 from './Step5.vue';

import { UserService, PaymentPlanService } from '@/services';
import { useIntegrations, useGlobalProps } from '@/composables';
import moment from 'moment';
import { RRule } from 'rrule';

export default defineComponent({
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    RightOutlined,
    LeftOutlined,
    CheckOutlined,
    DownloadOutlined,
    CloseOutlined,
  },
  props: {
    currentContact: {
      type: Object,
    },
  },
  setup() {
    /** State */
    const {
      paymentMethodSelected,
      setForm,
      setLoading,
      setTenantPictureBase64,
      user,
    } = useGlobalProps();
    const { setIntegrations } = useIntegrations();

    const data = reactive({
      /** Current step */
      step: 0,
      /** Steps data */
      steps: [
        { title: 'Contact', description: 'Details' },
        { title: 'Plan', description: 'Invoices' },
        { title: 'Review', description: 'Draft' },
        { title: 'Payment', description: 'Method' },
        { title: 'Summary', description: 'Finish' },
      ],
    });

    /** Dynamic current step component */
    const stepComponent = computed(() => {
      return `Step${data.step + 1}`;
    });

    /** Form all steps */
    const form: any = {};

    /** Methods */
    window.addEventListener('message', e => {
      const primaUrl = process.env.VUE_APP_PRIMA_URL.replace('com/', 'com');
      // Get the sent data
      if (e.origin === primaUrl) {
        const data = e.data;

        setTenantPictureBase64(data);
      }

      // If you encode the message in JSON before sending them,
      // then decode here
      // const decoded = JSON.parse(data);
    });

    /** Go to next step */
    const nextStep = () => data.step++;

    /** Go to previous step */
    const prevStep = () => data.step--;

    /** Create payment plan */
    const createPaymentPlan = async (result: any) => {
      const tmpUser: any = user.value;

      /** Init payment create add one invoice to quantity payments */

      /** Set rrule date lex charge */
      form.value.initialPayment.payOn = moment(
        form.value.initialPayment.payOn,
      ).format('MM/DD/YYYY HH:mm:ss');

      const dtstart =
        form.value.initialPayment.value === '' ||
        form.value.initialPayment.value === null
          ? form.value.initialPayment.payOn
          : form.value.dtstart;

      const stringDate = dtstart.split(' ')[0];
      const stringHour = dtstart.split(' ')[1];

      const year = stringDate.split('/')[2];
      const month = stringDate.split('/')[0];
      const day = stringDate.split('/')[1];
      const hour = stringHour.split(':')[0];
      const minutes = stringHour.split(':')[1];
      const seconds = stringHour.split(':')[2];

      // Create a rule:
      const rule = new RRule({
        freq:
          form.value.paymentPeriod === 'monthly' ? RRule.MONTHLY : RRule.WEEKLY,
        interval:
          form.value.paymentPeriod === 'monthly' ? 1 : form.value.paymentPeriod,
        dtstart: new Date(
          Date.UTC(year, month - 1, day, hour, minutes, seconds),
        ),
        count: form.value.payments,
      });

      try {
        const newPaymentPlan: any = {
          contactId: form.value.contact.id,
          caseId: form.value.case.id,
          totalPlan: form.value.totalPayment,
          firstPay:
            form.value.initialPayment.value === ''
              ? null
              : form.value.initialPayment.value,
          porcentage: form.value.initialPayment.modo === '%',
          quantityPays: form.value.payments,
          periodPays:
            form.value.paymentPeriod === 1
              ? 'weekly'
              : form.value.paymentPeriod === 2
              ? 'twoWeeks'
              : form.value.paymentPeriod === 3
              ? 'threeWeeks'
              : 'monthly',
          startDatePay: form.value.initialPayment.payOn,
          paymentMethodId: result.primaPay ? result.primaPay.id : null,
          accountId: result.account._id,
          description: form.value.description,
          payingParty: form.value.payingParty.fullName,
          termsAndConditions:
            tmpUser.termsAndConditions && tmpUser.termsAndConditions !== null
              ? tmpUser.termsAndConditions
              : 'Missing Terms & Conditions.',
          currentDate: moment().format('MM/DD/YYYY'),
          dataLexCharge: result.primaPay
            ? {
                rule: rule
                  .toString()
                  .replace('\nRRULE:', ';')
                  .replace(':', '='),
                merchantId: result.account.lexChargeAccountId,
                methodId: result.primaPay.id,
                customerId: result.primaPay.customer.id,
                type: result.type === 'e_check' ? 'eCheck' : result.type,
              }
            : null,
        };

        /** Mutation create payment plan */
        const { d }: any = await PaymentPlanService.create(newPaymentPlan);

        console.log(d);

        setLoading(false);

        message.success('Processing complete!');
        // Go to next page
        nextStep();
      } catch (error) {
        console.error(error);
      }
    };

    /** Hanlder for close stepper */
    const onClose = () => {
      window.top.postMessage(
        'close payment plan',
        process.env.VUE_APP_PRIMA_URL,
      );
    };

    /** Handler for done stepper */
    const onDone = async (validator: () => Promise<any>) => {
      try {
        const result = await validator();

        setLoading(true);

        createPaymentPlan(result);
      } catch (error) {
        console.error(error);
      }
    };

    /** Current "submit" event */
    const onSubmit = async (validateFn: () => Promise<any>) => {
      // Validation function not provided (continue for now)
      if (!validateFn) {
        nextStep();
        return;
      }

      try {
        // Data returned of the current step
        const data = await validateFn();
        // Save data in this component
        form.value = Object.assign({}, form.value, data);

        setForm(form.value);

        // Go to next page
        nextStep();
      } catch (error) {
        // Form error
        console.error(error);
      }
    };

    const executeRequests = async () => {
      try {
        // Get integrations
        const integrations = await UserService.getIntegrations();
        setIntegrations(integrations);
      } catch (error) {
        console.error(error);
      }
    };

    executeRequests();

    const onDonwload = async (download: () => Promise<any>) => {
      await download();
    };

    return {
      ...toRefs(data),
      stepComponent,
      nextStep,
      prevStep,
      onClose,
      onDone,
      onSubmit,
      onDonwload,
      paymentMethodSelected,
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  padding: 1rem;
}

.Stepper {
  margin: 0 auto;

  &__content {
    padding: 1rem;
    margin-top: 1rem;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;

    &-title {
      @include screen-sm {
        display: none;
      }
    }
  }

  &__actions {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
