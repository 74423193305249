<template>
  <AForm :model="form" layout="vertical" :rules="rules" ref="refForm">
    <div style="overflow-x: hidden; overflow-y: scroll; height: 490px">
      <AFormItem
        label="Choose Contact"
        name="contact"
        v-bind="validateInfos['contact']"
        required
      >
        <ASelect
          showSearch
          v-model:value="form.contact"
          show-search
          allow-clear
          placeholder="Choose Contact"
          label="children"
          :filter-option="false"
          :disabled="disabled.contact"
          @change="changeContact"
          @search="searchContact"
        >
          <ASelectOption
            v-for="contact of fetchData.contacts"
            :key="contact.id"
            :value="contact"
          >
            {{ contact.fullName }}
          </ASelectOption>
        </ASelect>
      </AFormItem>

      <AFormItem
        label="Paying Party"
        name="payingParty"
        v-bind="validateInfos['payingParty']"
        required
      >
        <AInputGroup compact>
          <!-- Select relations contact -->
          <ASelect
            v-if="!newPayingParty.show"
            v-model:value="form.payingParty"
            show-search
            allow-clear
            placeholder="Choose Paying Party"
            label="children"
            :filter-option="payingPartyFilterOption"
            :disabled="disabled.payingParty"
            style="width: calc(100% - 60px)"
          >
            <ASelectOption
              v-for="contact of fetchData.contactRelations"
              :key="contact.relatedContact.id"
              :value="contact.relatedContact"
            >
              {{ contact.relatedContact.fullName }}
            </ASelectOption>
          </ASelect>

          <!-- New name for paying party -->
          <AInput
            v-else
            v-model:value="newPayingParty.value"
            placeholder="New Name"
            style="width: calc(100% - 60px)"
            @pressEnter="setNewPayingParty"
          />

          <ATooltip placement="leftTop">
            <template #title>
              <span>{{ newPayingParty.show ? 'List' : 'Add New' }}</span>
            </template>
            <AButton
              :disabled="disabled.payingParty"
              style="width: 60px"
              tooltip="Add New"
              @click="newPayingParty.show = !newPayingParty.show"
            >
              <UnorderedListOutlined v-if="newPayingParty.show" />
              <PlusCircleOutlined v-else />
            </AButton>
          </ATooltip>
        </AInputGroup>
      </AFormItem>

      <AFormItem
        label="Choose Case"
        name="case"
        v-bind="validateInfos['case']"
        required
      >
        <ASelect
          v-model:value="form.case"
          show-search
          allow-clear
          placeholder="Choose Case"
          label="children"
          :filter-option="caseFilterOption"
          :disabled="disabled.case"
        >
          <ASelectOption
            v-for="currentCase of fetchData.cases"
            :key="currentCase.id"
            :value="currentCase"
          >
            {{ generateFullCaseName(currentCase) }}
          </ASelectOption>
        </ASelect>
      </AFormItem>
    </div>

    <slot :validateFn="validateForm" />
  </AForm>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRaw } from 'vue';
import { ContactsService, HelpersService } from '@/services';
import { useForm } from '@ant-design-vue/use';
import { ContactCase, ContactRelated } from '@/types/contacts-service.type';
import { AntSelectOption } from '@/types/ant-design';
import {
  PlusCircleOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    PlusCircleOutlined,
    UnorderedListOutlined,
  },

  props: {
    currentContact: {
      type: Object,
    },
  },

  setup(props) {
    /** State */

    const refForm = ref(null);

    const form = reactive({
      contact: undefined,
      payingParty: undefined,
      case: undefined,
    });

    const newPayingParty = reactive({
      show: false,
      value: '',
    });

    const disabled = reactive({
      contact: false,
      payingParty: true,
      case: true,
    });

    const rules = reactive({
      contact: [
        {
          required: true,
          message: 'Please select a Contact',
          trigger: 'change',
          type: 'object',
        },
      ],
      payingParty: [
        {
          required: true,
          message: 'Please select a Paying Party',
          trigger: 'change',
          type: 'object',
        },
      ],
      case: [
        {
          required: true,
          message: 'Please select a Case',
          trigger: 'change',
          type: 'object',
        },
      ],
    });

    const fetchData = reactive({
      contacts: [] as any[],
      contactRelations: [] as any[],
      cases: [] as any[],
      defaultContacts: [] as any[],
    });

    /** Form validation result (AntDesign) */
    const { validateInfos, validate, resetFields } = useForm(form, rules);

    /** Methods */
    const debounce = HelpersService.createDebounce();

    /** Generate full case name */
    const generateFullCaseName = (currentCase: ContactCase) => {
      return `${currentCase.name} | ${currentCase.caseType.name}`;
    };

    /** 'Case' select filter option */
    const caseFilterOption = (input: string, option: any) => {
      input = input.trim();

      const fullName = generateFullCaseName(option.value);
      return fullName.toLowerCase().includes(input.toLowerCase());
    };

    /** 'Paying Party' select filter option */
    const payingPartyFilterOption = (
      input: string,
      option: AntSelectOption<ContactRelated>,
    ) => {
      input = input.trim();

      return option.value.fullName.toLowerCase().includes(input.toLowerCase());
    };

    /** List paying party */
    const getContactRelations = async () => {
      try {
        const contact: any = form.contact;
        const { data }: any = await ContactsService.relations(contact.id);
        // Set selected contact for paying party
        data.data.contactsRelationed.unshift({ relatedContact: contact });

        fetchData.contactRelations = data.data.contactsRelationed;
      } catch (error) {
        console.error(error);
      }
    };

    /** List contacts */
    const getContacts = async () => {
      try {
        const { data }: any = await ContactsService.searchContact({
          limit: 6,
        });

        fetchData.contacts = data.data.contacts;
        fetchData.defaultContacts = data.data.contacts;
      } catch (error) {
        console.error(error);
      }
    };

    /** Search contacts */
    const searchContact = async (val: string) => {
      // Clear whitespaces
      val = val.trim();

      // Empty string, set
      if (!val) {
        // Set default contacts to list
        if (fetchData.defaultContacts.length) {
          fetchData.contacts = fetchData.defaultContacts;
        } else {
          // Get from backend again
          getContacts();
        }
        return;
      }

      // Execute search
      debounce(async () => {
        const { data }: any = await ContactsService.searchContact({
          limit: 6,
          filter: [
            {
              field: 'fullName',
              value: val,
              condition: 'like',
            },
          ],
        });

        fetchData.contacts = data.data.contacts;
      });
    };

    /** Validate form */
    const validateForm = async () => {
      let done = true;

      // Validate other fields
      try {
        await validate();
      } catch (error) {
        done = false;
      }

      if (!done) {
        return Promise.reject(new Error('Invalid form values'));
      }

      return Promise.resolve(toRaw(form));
    };

    /** Set cases from choosed contact */
    const setCases = (contactId: number) => {
      fetchData.cases = fetchData.contacts.filter(
        a => a.id === contactId,
      )[0].cases;
    };

    /** On change contact */
    const changeContact = (contact: any) => {
      // Input was cleaned
      if (contact === undefined) {
        resetFields();

        disabled.payingParty = true;
        disabled.case = true;

        // Get default contats
        if (fetchData.defaultContacts.length) {
          fetchData.contacts = fetchData.defaultContacts;
        } else {
          getContacts();
        }
        return;
      }

      // Enable fields
      if (disabled.payingParty || disabled.case) {
        disabled.payingParty = false;
        disabled.case = false;
      }

      // Get contact relations & set case from choosed contact
      getContactRelations();
      setCases(contact.id);

      resetFields({
        contact: contact,
        payingParty: undefined,
        case: undefined,
      });
    };

    /** Set case vista Prima */
    const setCurrentCase = () => {
      if (props.currentContact && props.currentContact.caseId !== 0) {
        const currentCaseId = props.currentContact.caseId;

        form.case = fetchData.cases.filter(
          caseData => caseData.id === currentCaseId,
        )[0];
      }
    };

    /** Get contact vista Prima */
    const getCurrentContact = async (currentContactId: string) => {
      try {
        const { data }: any = await ContactsService.searchContact({
          limit: 6,
          filter: [
            {
              field: 'id',
              value: currentContactId,
              condition: '',
            },
          ],
        });

        fetchData.contacts = data.data.contacts;
        form.contact = data.data.contacts[0];

        changeContact(form.contact);
        setCurrentCase();
      } catch (error) {
        console.log(error);
      }
    };

    if (props.currentContact && props.currentContact.id !== 0) {
      getCurrentContact(props.currentContact.id);
    } else {
      // Get contacts on create component
      getContacts();
    }

    const setNewPayingParty = () => {
      fetchData.contactRelations.push({
        relatedContact: {
          id: Math.random()
            .toString(36)
            .substring(7),
          fullName: newPayingParty.value,
        },
      });

      form.payingParty =
        fetchData.contactRelations[
          fetchData.contactRelations.length - 1
        ].relatedContact;

      newPayingParty.value = '';
      newPayingParty.show = false;
    };

    return {
      form,
      disabled,
      rules,
      fetchData,
      caseFilterOption,
      payingPartyFilterOption,
      refForm,
      getContacts,
      getContactRelations,
      validateForm,
      validateInfos,
      changeContact,
      setCases,
      searchContact,
      generateFullCaseName,
      newPayingParty,
      setNewPayingParty,
      props,
    };
  },
});
</script>

<style lang="scss" scoped>
.Contact {
  display: flex;
}
</style>
