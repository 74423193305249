
import { defineComponent } from 'vue';
import ViewerPdf from './ViewerPdf.vue';

export default defineComponent({
  components: { ViewerPdf },
  setup() {
    return {};
  },
});
