<template>
  <AForm :model="form" layout="vertical" :rules="rules" ref="refForm">
    <div style="overflow-x: hidden; overflow-y: scroll; height: 490px">
      <ARow :gutter="12">
        <ACol :span="12">
          <AFormItem label="Total Payment ($)" style="margin-bottom: 0">
            <AInputGroup compact>
              <AFormItem
                name="totalPayment"
                v-bind="validateInfos['totalPayment']"
                style="width: 100%"
              >
                <AInputNumber
                  v-model:value="form.totalPayment"
                  type="number"
                  placeholder="Total"
                  style="width: 100%"
                />
              </AFormItem>
            </AInputGroup>
          </AFormItem>
        </ACol>

        <ACol :span="12">
          <AFormItem
            label="Initial Payment (Optional)"
            name="initialPayment"
            v-bind="validateInfos['initialPayment.value']"
          >
            <AInputGroup compact>
              <AInputNumber
                v-model:value="form.initialPayment.value"
                type="number"
                placeholder="Initial Payment"
                style="width: calc(100% - 60px)"
              />
              <ASelect
                v-model:value="form.initialPayment.modo"
                style="width: 60px"
                @change="form.initialPayment.value = null"
              >
                <ASelectOption value="%"> % </ASelectOption>
                <ASelectOption value="$"> $ </ASelectOption>
              </ASelect>
            </AInputGroup>
          </AFormItem>
        </ACol>

        <ACol :xs="{ span: 12 }" :lg="{ span: 8 }">
          <AFormItem
            label="Pay On"
            name="payOn"
            v-bind="validateInfos['initialPayment.payOn']"
          >
            <ADatePicker
              v-model:value="form.initialPayment.payOn"
              :disabled-date="disabledInitialPaymentDate"
              format="MM/DD/YYYY"
              placeholder="Pay On"
              style="width: 100%"
            />
          </AFormItem>
        </ACol>

        <ACol :xs="{ span: 12 }" :lg="{ span: 8 }">
          <AFormItem
            label="Number Of Payments"
            name="payments"
            v-bind="validateInfos['payments']"
            style="width: 100%"
          >
            <ASelect
              v-model:value="form.payments"
              style="width: 100%"
              placeholder="Payments"
              allow-clear
              show-search
              :filter-option="filterOption"
            >
              <ASelectOption v-for="val of 104" :key="val" :value="val">
                {{ val }}
              </ASelectOption>
            </ASelect>
          </AFormItem>
        </ACol>

        <ACol :xs="{ span: 24 }" :lg="{ span: 8 }">
          <AFormItem
            label="Payment Period"
            v-bind="validateInfos['paymentPeriod']"
          >
            <ASelect
              v-model:value="form.paymentPeriod"
              placeholder="Payment Period"
              style="text-align: left"
            >
              <ASelectOption
                v-for="val of paymentPeriodValues"
                :key="val.value"
                :value="val.value"
              >
                {{ val.label }}
              </ASelectOption>
            </ASelect>
          </AFormItem>
        </ACol>
      </ARow>

      <AFormItem label="Invoices" v-if="showInvoicesTable">
        <ATable
          :columns="columns"
          :data-source="form.invoices"
          :row-key="v => v.invoiceNumber"
          bordered
          size="small"
        />
      </AFormItem>

      <AFormItem>
        <ACheckbox v-model:checked="form.terms">Terms and conditions</ACheckbox>
        <small style="display: block; color: grey">
          Will be displayed in Review Draft
        </small>
      </AFormItem>

      <AFormItem>
        <ATextarea
          v-model:value="form.description"
          placeholder="Notes"
          :rows="3"
        />
      </AFormItem>
    </div>

    <slot :validateFn="validateForm" />
  </AForm>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  watchEffect,
  toRaw,
  computed,
} from 'vue';
import { Validator } from '@/utils/ant-custom-validators';
import { useForm } from '@ant-design-vue/use';
import moment from 'moment';

export default defineComponent({
  setup() {
    const refForm = ref(null);

    /** Form data entry */
    const form = reactive({
      totalPayment: '',
      payments: undefined as undefined | number,
      paymentPeriod: undefined as undefined | number | string,
      initialPayment: {
        modo: '%',
        value: '' as string | number,
        payOn: moment().add(1, 'days'),
      },
      terms: true,
      description: '',
      invoices: ref<any[]>([]),
      dtstart: '' as string,
    });

    /** Form validation rules */
    const rules = reactive({
      totalPayment: [
        {
          validator: Validator.rangeTotal(form),
          trigger: 'change',
        },
      ],
      payments: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      'initialPayment.value': [
        {
          validator: Validator.range(form),
          trigger: 'change',
        },
      ],
      'initialPayment.payOn': [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
      paymentPeriod: [
        {
          validator: Validator.isRequired(),
          trigger: 'change',
        },
      ],
    });

    /** Form validation result (AntDesign) */
    const { validateInfos, validate } = useForm(form, rules);

    /** Datatable columns */
    const columns = [
      {
        title: 'Invoice',
        dataIndex: 'invoiceNumber',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.invoiceNumber - b.invoiceNumber,
        align: 'right',
      },
      {
        title: 'Due Date',
        dataIndex: 'dueDate',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.dueDate - b.dueDate,
        align: 'right',
      },
      {
        title: 'Amount to Pay ($)',
        dataIndex: 'amount',
        defaultSortOrder: 'ascend',
        sorter: (a: any, b: any) => a.amount - b.amount,
        align: 'right',
      },
    ];

    /** Payment period values */
    const paymentPeriodValues = [
      { label: 'Weekly', value: 1 },
      { label: 'Every 2 Weeks', value: 2 },
      { label: 'Every 3 Weeks', value: 3 },
      { label: 'Monthly', value: 'monthly' },
    ];

    /** Computed properties */
    const showInvoicesTable = computed(() => {
      const initialPaymentStatus =
        validateInfos['initialPayment.payOn'].validateStatus;

      if (!!initialPaymentStatus && initialPaymentStatus === 'error') {
        return false;
      }

      return !(
        !form.payments ||
        !form.totalPayment ||
        !form.initialPayment.payOn ||
        !form.paymentPeriod
      );
    });

    /** Methods */
    const filterOption = (input: string, option: any) => {
      return (
        String(option.value)
          .toLowerCase()
          .indexOf(String(input).toLowerCase()) >= 0
      );
    };

    /** Validate form */
    const validateForm = async () => {
      let done = true;

      // Validate other fields
      try {
        await validate();
      } catch (error) {
        done = false;
      }

      if (!done) {
        return Promise.reject(new Error('Invalid form values'));
      }

      return Promise.resolve(toRaw(form));
    };

    const setInvoices = async () => {
      const tmpInvoicesData = [];

      if (
        form.totalPayment &&
        form.payments &&
        form.initialPayment.payOn &&
        form.paymentPeriod
      ) {
        const payOnFormat = moment(form.initialPayment.payOn).format(
          'MM/DD/YYYY',
        );
        let initialPayment = 0;
        let fees = 0;
        let fee = 0;

        if (typeof form.totalPayment === 'number') {
          if (form.initialPayment.modo === '%') {
            initialPayment =
              (form.totalPayment * Number(form.initialPayment.value)) / 100;
          } else {
            initialPayment = Number(form.initialPayment.value);
          }

          if (initialPayment < 0 || initialPayment >= form.totalPayment) {
            form.invoices = [];
            return;
          }

          fees = form.totalPayment - initialPayment;

          fee = Number((fees / form.payments).toFixed(2));
        }

        if (
          form.initialPayment.value &&
          form.initialPayment.value !== null &&
          form.initialPayment.value !== 0
        ) {
          const invoice = {
            invoiceNumber: 'Init. Pay',
            dueDate: payOnFormat,
            amount: initialPayment,
          };

          tmpInvoicesData.push(invoice);
        }

        for (
          let contPayment: any = 0;
          contPayment < form.payments;
          contPayment++
        ) {
          const invoice = {
            invoiceNumber: Number(contPayment + 1),
            dueDate: payOnFormat,
            amount: 0,
          };

          invoice.amount = fee;

          if (
            (!form.initialPayment.value ||
              form.initialPayment.value === null ||
              form.initialPayment.value === 0) &&
            contPayment === 0
          )
            invoice.dueDate = payOnFormat;
          else {
            let tmpDueDate;
            let tmpValue;
            const periodNumber =
              !form.initialPayment.value ||
              form.initialPayment.value === null ||
              form.initialPayment.value === 0
                ? contPayment
                : contPayment + 1;

            if (form.paymentPeriod === 'monthly') {
              tmpDueDate = moment(form.initialPayment.payOn).add(
                periodNumber,
                'months',
              );
              invoice.dueDate = moment(tmpDueDate).format('MM/DD/YYYY');
            } else {
              tmpValue = Number(form.paymentPeriod) * 7 * periodNumber;

              tmpDueDate = moment(form.initialPayment.payOn).add(
                tmpValue,
                'days',
              );
              invoice.dueDate = moment(tmpDueDate).format('MM/DD/YYYY');
            }

            if (
              form.initialPayment.value &&
              form.initialPayment.value !== null &&
              form.initialPayment.value !== 0 &&
              form.dtstart === ''
            )
              form.dtstart = moment(tmpDueDate).format('MM/DD/YYYY HH:mm:ss');
          }

          tmpInvoicesData.push(invoice);
        }

        form.invoices = tmpInvoicesData;
      }
    };

    /** Can not select days before today and more than 1 month */
    const disabledInitialPaymentDate = (current: moment.Moment) => {
      const oneMonth = moment().add('month', 1);

      if (
        form.initialPayment.value &&
        form.initialPayment.value !== null &&
        form.initialPayment.value !== 0
      ) {
        return (
          current && (current <= moment().startOf('day') || current > oneMonth)
        );
      } else {
        return (
          current &&
          (current <
            moment()
              .add(1, 'days')
              .startOf('day') ||
            current > oneMonth)
        );
      }
    };

    watchEffect(() => {
      setInvoices();

      if (
        (!form.initialPayment.value || form.initialPayment.value === 0) &&
        form.initialPayment.payOn < moment()
      )
        form.initialPayment.payOn = moment().add(1, 'days');
    });

    return {
      form,
      rules,
      paymentPeriodValues,
      showInvoicesTable,
      filterOption,
      refForm,
      columns,
      setInvoices,
      validateForm,
      validateInfos,
      disabledInitialPaymentDate,
    };
  },
});
</script>

<style lang="scss" scoped>
.Contact {
  display: flex;
}
</style>
